define("discourse/plugins/discourse-topic-noindex/discourse/initializers/add-noindex-item-to-topic-wrench-menu", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-topic-noindex",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.addTopicAdminMenuButton(topic => {
          const canManageTopic = api.getCurrentUser()?.canManageTopic;
          if (canManageTopic && !topic.isPrivateMessage) {
            return {
              className: "toggle-noindex",
              icon: topic.noindex ? "far-eye" : "far-eye-slash",
              label: topic.noindex ? "topic.actions.noindex_stop" : "topic.actions.noindex",
              action: async () => {
                await (0, _ajax.ajax)(`/t/${topic.id}/toggle-noindex`, {
                  type: "PUT"
                });
                topic.reload();
              }
            };
          }
        });
      });
    }
  };
});